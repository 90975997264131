import React from "react";
import Header from "./header";
import Footer from "./footer";
import CustomBanner from "../cookie/customBanner";

import "../../styles/style.css";

const Layout = props => {
  return (
    <div className="container">
      <Header />
      <div>{props.children}</div>
      <CustomBanner />
      <Footer />
    </div>
  );
};

export default Layout;
