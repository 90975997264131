import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Rainbow from "./rainbow";
import layoutStyles from "./layout.module.css";

const Footer = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
          }
        }
      }
    `
  );

  return (
    <footer>
      <Rainbow />
      <Navbar>
        <Navbar.Collapse className={layoutStyles.navigation}>
          <Nav className={layoutStyles.secondary}>
            <Nav.Item>
              <Link to="/" className={layoutStyles.button}>
                Home
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link to="/governance/" className={layoutStyles.button}>
                Governance
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link to="/privacy/" className={layoutStyles.button}>
                Privacy
              </Link>
            </Nav.Item>
          </Nav>
          <Nav className={layoutStyles.tertiary}>
            <Nav.Item className="d-none d-md-block">
              © {new Date().getFullYear().toString()}{" "}
              {data.site.siteMetadata.author}
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </footer>
  );
};

export default Footer;
