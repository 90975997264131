import React from "react";
import layoutStyles from "./layout.module.css";

const Rainbow = () => {
  return (
    <div className={layoutStyles.rainbow}>
      <div className={`${layoutStyles.secondary}`}></div>
      <div className={`${layoutStyles.tertiary}`}></div>
    </div>
  );
};

export default Rainbow;
