import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import defaultOpenGraphImage from "../../images/og-images/Mountain_Range_V1.jpg";

const Metadata = ({ title, description, socialBanner }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );
  const metaTitle = title || data.site.siteMetadata.title;
  const metaDescription = description || data.site.siteMetadata.description;
  const metaImage = socialBanner || defaultOpenGraphImage;

  return (
    <Helmet>
      {/* General Tags */}
      <title>{`${metaTitle} | ${data.site.siteMetadata.title}`}</title>
      <meta name="description" content={metaDescription} />
      <meta name="author" content={data.site.siteMetadata.author} />
      {/* OpenGraph Tags */}
      <meta property="og:url" content={data.site.siteMetadata.siteUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta
        property="og:image"
        name="image"
        content={`${data.site.siteMetadata.siteUrl}${metaImage}`}
      />
      <meta
        property="twitter:image"
        name="image"
        content={`${data.site.siteMetadata.siteUrl}${metaImage}`}
      />
      <meta property="og:image:alt" content="Hope Labs social media banner" />
      <meta property="og:site_name" content={data.site.siteMetadata.title} />
      <meta property="og:locale" content="en_GB" />
      {/* Schema.org Tags */}
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "url": "https://hopelabs.co.uk",
            "name": "Hope Labs",
            "contactPoint": {
              "@type": "ContactPoint",
              "email": "info@hopelabs.co.uk",
              "contactType": "Information and Support"
            }
          }
      `}
      </script>
    </Helmet>
  );
};

export default Metadata;

Metadata.defaultProps = {
  title: "",
  description: "",
  socialBanner: undefined,
};

Metadata.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  socialBanner: PropTypes.string,
};
