import React from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { Link } from "gatsby";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useLocation } from "@reach/router";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import cookieStyle from "./cookie.module.css";

const CustomBanner = props => {
  let location = useLocation();

  const onAcceptFunction = () => {
    initializeAndTrack(location);
    Cookies.set("gatsby-gdpr-google-analytics", true);
    Cookies.set("gatsby-gdpr-google-tagmanager", true);
    Cookies.set("gatsby-gdpr-facebook-pixel", true);
  };

  return (
    <Row className={"presentation no-margin"}>
      <Col>
        <CookieConsent
          disableStyles={true}
          location={"none"}
          overlay={true}
          overlayStyle={{
            position: "fixed",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            zIndex: "998",
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
          containerClasses={cookieStyle.cookieBox}
          buttonText="Accept"
          onAccept={onAcceptFunction}
          ariaAcceptLabel={"Accept our Cookie Policy"}
          buttonClasses={cookieStyle.acceptButton}
          enableDeclineButton={true}
          declineButtonText={"Essential Navigation"}
          ariaDeclineLabel={"Essential Cookies Only"}
          onDecline={() => {}}
          declineButtonClasses={cookieStyle.infoButton}
          contentClasses={cookieStyle.contentPosition}
          cookieName="hopelabs-cookie-consent"
          expires={365}
          acceptOnScroll={true}
          acceptOnScrollPercentage={5}
        >
          <Row className={"presentation no-margin"}>
            <Col>
              <h4>We use cookies to improve your experience</h4>
              <p>
                We use cookies essential for this site to function well. Please
                click "Accept" to help us improve its usefulness with additional
                cookies. Learn about our use of cookies, and collaboration with
                trusted analytics partners in our{" "}
                <Link to={"/cookies/"}>Cookie Policy</Link>. If you click on
                "Essential Navigation", you may be unable to access certain
                parts of this website.
              </p>
            </Col>
          </Row>
        </CookieConsent>
      </Col>
    </Row>
  );
};

export default CustomBanner;
