import React, { useState } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Rainbow from "./rainbow";
import layoutStyles from "./layout.module.css";
import WindowSize from "../../utilities/WindowSize";

import { IconContext } from "@react-icons/all-files";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import { FaYoutube } from "@react-icons/all-files/fa/FaYoutube";

const IconStyle = props => {
  return (
    <IconContext.Provider value={{ className: "header-icons" }}>
      {props.children}
    </IconContext.Provider>
  );
};

const Header = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            description
            linkedinUrl
            youtubeUrl
          }
        }
      }
    `
  );

  const slogans = data.site.siteMetadata.description.split(".").filter(Boolean);

  let [index, setIndex] = useState(0);

  const incrementIndex = () => {
    setIndex((index + 1) % slogans.length);
  };

  let window = WindowSize();

  return (
    <header>
      <Navbar className={layoutStyles.navbar} expand="lg">
        <Navbar.Brand className={layoutStyles.brand}>
          <Link to="/">
            <div
              className={`${
                window.width >= 992
                  ? layoutStyles.navbarLogoBig
                  : layoutStyles.navbarLogoSmall
              }`}
            ></div>
          </Link>
          <Navbar.Text
            className={`${layoutStyles.description} d-none d-lg-block`}
            onAnimationIteration={incrementIndex}
          >
            {slogans[index]}
          </Navbar.Text>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className={layoutStyles.navigation}>
          <Nav className={layoutStyles.primary}>
            <Nav.Item>
              <Link to="/stories/" className={layoutStyles.button}>
                Stories
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link to="/values/" className={layoutStyles.button}>
                Values
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link to="/team/" className={layoutStyles.button}>
                Team
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link to="/services/" className={layoutStyles.button}>
                Services
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link to="/contact/" className={layoutStyles.button}>
                Contact Us
              </Link>
            </Nav.Item>
          </Nav>
          <Nav className={layoutStyles.primary}>
            <Nav.Item>
              <a
                href={data.site.siteMetadata.linkedinUrl}
                target="_blank"
                rel="noreferrer"
              >
                <div
                  role="button"
                  aria-label="LinkedIn"
                  className={`${layoutStyles.button} ${layoutStyles.icon}`}
                >
                  <IconStyle>
                    <FaLinkedin />
                  </IconStyle>
                </div>
              </a>
            </Nav.Item>
            <Nav.Item>
              <a
                href={data.site.siteMetadata.youtubeUrl}
                target="_blank"
                rel="noreferrer"
              >
                <div
                  role="button"
                  aria-label="Youtube"
                  className={`${layoutStyles.button} ${layoutStyles.icon}`}
                >
                  <IconStyle>
                    <FaYoutube />
                  </IconStyle>
                </div>
              </a>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Rainbow />
    </header>
  );
};

export default Header;
